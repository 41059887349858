import { css } from '@emotion/react';
import styled from '@emotion/styled';
import svgToDataUrl from '../../helpers/svg-to-data-url';

const selectArrowSvg = ({ theme }, quote) =>
  svgToDataUrl(
    `
<svg fill='${theme.colors.trout}' height='4' width='8' viewBox='0 0 8 4' xmlns='http://www.w3.org/2000/svg'>
<path d='M0 0l4 4 4-4z'/>
</svg>`,
    quote
  );

export const selectFieldStyle = ({ theme }) => css`
  display: block;
  isolation: isolate;
  position: relative;

  width: 100%;
  overflow: hidden;

  select {
    ${theme.typography.control};
    height: 48px;
    width: 100%;
    padding: 0 14px;
    border-radius: 0;

    border: solid thin ${theme.colors.quartz};
    border-radius: 4px;
    background-color: ${theme.colors.white};

    appearance: none;

    background-image: url(${selectArrowSvg({ theme }, true)});
    background-repeat: no-repeat;
    background-position-x: calc(100% - 18px);
    background-position-y: 50%;

    transition: border-color 150ms linear;

    &:focus-visible {
      outline: none;
      border-color: ${theme.colors.bahamaBlue};
    }
  }
`;

export default styled.div`
  ${selectFieldStyle};
`;
