import React from 'react';
import StyledHubSpotForm from './HubSpotForm.style';
import dataLayer from '../app/helpers/dataLayer';

const isAncestor = (parent, child) => {
  let node = child?.parentNode || null;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node?.parentNode || null;
  }
  return false;
};

const HubSpotForm = ({
  onBeforeFormInit,
  onBeforeValidationInit,
  onFormReady,
  onFormSubmit,
  onFormSubmitted,
  presetFields,
  ...p
}) => {
  const ref = React.useRef();
  React.useEffect(() => {
    const handler = (event) => {
      if (event?.data?.type !== 'hsFormCallback') return;
      const form = document.querySelector(
        `form[data-form-id="${event.data?.id}"]`
      );

      if (!isAncestor(ref.current, form)) return;
      switch (event.data?.eventName) {
        case 'onBeforeFormInit':
          onBeforeFormInit?.(ref.current);
          break;
        case 'onBeforeValidationInit':
          onBeforeValidationInit?.(ref.current);
          break;
        case 'onFormReady':
          onFormReady?.(ref.current);
          break;
        case 'onFormSubmit':
          onFormSubmit?.(ref.current, event.data.data);
          if (p.formTitle?.toLowerCase() === 'contact') {
            try {
              const eventData = {
                event: 'contact_submit',
                page_location: window.location.href,
              };
              dataLayer(eventData);
            } catch (e) {
              //ignore
            }
          }
          break;
        case 'onFormSubmitted':
          onFormSubmitted?.(ref.current);
          break;
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [ref.current]);
  return <StyledHubSpotForm ref={ref} {...p} />;
};

export default HubSpotForm;
