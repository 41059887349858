import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { buttonStyle } from './global/Button.style';
import { numberFieldStyle } from './global/NumberField.style';
import { selectFieldStyle } from './global/NativeSelectField.style';
import { switchStyle } from './global/Switch.style';
import { textFieldPrefixStyle, textFieldStyle } from './global/TextField.style';

const textAreaStyle = ({ theme }) => css`
  textarea {
    position: relative;
    display: block;
    width: 100%;
    height: 130px;
    ${theme.typography.control};
    padding: 10px 14px;
    border-radius: 4px;
    border: solid thin ${theme.colors.quartz};
    background-color: ${theme.colors.white};
    transition: border-color 150ms linear;
    resize: none;

    &:focus-visible {
      outline: none;
      border-color: ${theme.colors.bahamaBlue};
    }

    &.error {
      border-color: ${theme.colors.error};
      &:focus-visible {
        border-color: ${theme.colors.error};
      }
    }
  }
`;

const formStyle = ({ theme }) => css`
  @keyframes hs-form-error-fadein {
    0% {
      line-height: 0%;
    }
    100% {
      line-height: 180%;
    }
  }
  form.hs-form {
    fieldset {
      max-width: unset;
    }
    fieldset.form-columns-2,
    fieldset.form-columns-3 {
      div.hs-form-field input.hs-input {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      ${theme.media.tablet} {
        flex-direction: row;
        gap: 15px;
      }
    }
    div.hs-form-field {
      span.hs-form-required {
        display: inline-block;
        color: transparent;
        font-size: 0;
        height: 14px;
        &:before {
          ${theme.typography.label};
          color: ${theme.colors.pacificBlue};
          content: '*';
          margin-left: 6px;
        }
      }
      margin-bottom: 15px;
      & > label {
        ${theme.typography.label};
        margin-bottom: 5px;
        display: inline-block;
      }
      & > legend.hs-field-desc {
        ${theme.typography.formLegend};
      }
      & > .hs-error-msgs {
        margin: 5px 0 0;
        ${theme.typography.formError};
        animation: hs-form-error-fadein 250ms 1;
      }
      width: 100%;
      float: unset;
      max-width: 380px;
      &.hs-fieldtype-textarea {
        max-width: unset;
      }
    }
    div.input > input.hs-input.error {
      border-color: ${theme.colors.error};
      &:focus-visible {
        border-color: ${theme.colors.error};
      }
    }
    div.hs-fieldtype-text,
    div.hs-fieldtype-phonenumber {
      div.input {
        ${textFieldStyle({ theme })};
      }
    }
    div.hs-fieldtype-number {
      div.input {
        ${numberFieldStyle({ theme })};
        &[data-reactid*='bedrag'] {
          ${textFieldPrefixStyle({ theme, prefix: '€' })};
        }
      }
    }
    div.hs-fieldtype-select {
      div.input {
        ${selectFieldStyle({ theme })};
      }
    }
    div.hs-fieldtype-textarea {
      div.input {
        margin: 0;
        ${textAreaStyle({ theme })};
      }
    }
    button,
    input[type='button'],
    input[type='submit'] {
      ${buttonStyle({ theme, large: true })};
      width: 100%;
      max-width: 380px;
    }
    ul.inputs-list {
      padding: 0;
      li {
        display: block;
      }
    }
    label.hs-form-booleancheckbox-display,
    label.hs-form-checkbox-display {
      ${switchStyle({ theme })}
      &>span {
        margin: 0;
      }
    }
  }
`;

export default styled.div`
  ${formStyle}
`;
