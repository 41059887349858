import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const switchStyle = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  gap: 15px;

  input[type='checkbox'] {
    --container-height: 1.5em;
    --container-width: 3em;
    --grip-size: calc(var(--container-height) - 2px);
    position: relative;
    display: inline-block;
    width: var(--container-width);
    min-width: var(--container-width);
    height: var(--container-height);
    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      background-color: ${theme.colors.whiteSmoke};
      border: solid thin ${theme.colors.neutralGray};
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 999px;
      transition: background-color 150ms linear, border-color 150ms linear;
    }
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      background-color: ${theme.colors.white};
      left: 1px;
      top: 1px;
      width: var(--grip-size);
      height: var(--grip-size);
      margin: 0;
      border-radius: 999px;
      transition: transform 150ms ease-in-out;
    }
    &:checked {
      &::before {
        background-color: ${theme.colors.pacificBlue};
        border-color: ${theme.colors.pacificBlue};
      }
      &::after {
        transform: translateX(
          calc(var(--container-width) - var(--grip-size) - 2px)
        );
      }
    }
    &:disabled {
      &::after {
        background-color: ${theme.colors.neutralGray};
      }
    }
  }
`;

export default styled.label`
  ${switchStyle}
`;
